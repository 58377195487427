<template>
  <div>
    <el-form ref="form" :rules="rules" :model="form" label-width="80px">
      <el-form-item label="开启对比" prop="time" class="custom-form-item">
        <el-radio v-model="form.isCompare" :label="false">关闭</el-radio>
        <el-radio v-model="form.isCompare" :label="true">开启</el-radio>
      </el-form-item>
      <el-form-item label="时间" prop="time" class="custom-form-item">
        <el-date-picker v-model="form.time" type="month" :clearable="false" @change="getByAbarnTestingTime"
          placeholder="选择日期" style="width: 100%;">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="测温数据" prop="id" v-if="form.isCompare" class="custom-form-item">
        <el-select v-model="form.id" placeholder="请选择" style="width: 100%;">
          <el-option v-for="(item,index) in tempList" :key="index" :label="item.tempTime" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="测温数据" v-else class="custom-form-item">
        <el-input placeholder="测温数据" disabled />
      </el-form-item>
    </el-form>
    <div class="bottom-btn">
      <el-button type="primary" @click="onFormSubmit('form')">确 定</el-button>
      <el-button @click="closeDialog">关 闭</el-button>
    </div>
  </div>
</template>

<script>
  var moment = require('moment');
  export default {
    props: {
      houseId: {
        type: Number
      },
      state: {
        type: Boolean,
        default: true
      },
    },
    data() {
      return {
        form: {
          isCompare: false,
          time: new Date(),
          id: ''
        },
        rules: {
          isCompare: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          id: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }]
        },
        tempList: []
      }
    },
    created() {
      this.form.isCompare = this.state
      this.getByAbarnTestingTime()
    },
    methods: {
      //导出打印提交
      onFormSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$emit("confirm", this.form)
          } else {
            return false;
          }
        });
      },
      //关闭
      closeDialog() {
        this.$emit("close", {})
      },
      //获取测温数据
      async getByAbarnTestingTime() {
        let res = await this.$axios.Get(this.$api.byAbarnTestingTime, {
          "locaId": this.houseId,
          "month": moment(this.form.time).format('YYYY-MM')
        })
        this.tempList = res.data
      },
    }
  }
</script>

<style>
</style>
